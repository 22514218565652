import React from "react"
import ContextProvider from "../context"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Doctor from "../components/Doctor/Doctor"
import InfoSection from "../components/InfoSection/InfoSection"
import LocationSection from "../components/LocationSection/LocationSection"
import GallerySection from "../components/GallerySection/GallerySection"
import ExperienceSection from "../components/ExperienceSection/ExperienceSection"
import ReviewSection from "../components/ReviewSection/ReviewSection"

const IndexPage = () => {
  return (
    <ContextProvider>
      <Layout>
        <SEO title="Ortopedia y Traumatología" />
        <Doctor />
        <InfoSection />
        <LocationSection />
        <GallerySection />
        <ReviewSection />
        <ExperienceSection />
      </Layout>
    </ContextProvider>
  )
}

export default IndexPage
