import React from "react"
import classes from "./ExperienceColumn.module.css"

const ExperienceColumn = ({ SvgImage, itemsList }) => {
  return (
    <div className={classes.root}>
      <SvgImage />
      <ul>
        {itemsList.map(item => {
          return <li key={item}>{item}</li>
        })}
      </ul>
    </div>
  )
}

export default ExperienceColumn
