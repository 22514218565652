/*global google*/
import React, { useState } from "react"
import classes from "./LocationSection.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Button from "react-bootstrap/Button"
import Map from "./Map"

const GOOGLE_API_KEY = "AIzaSyAESTgsWviw57GzH9FyCCYAi4wZLxfu1dc";

const LocationSection = () => {
  const [directions, setDirections] = useState(null)
  const [disable, setDisable] = useState(false)

  const handleRoute = destination => {
    if (!navigator.geolocation) {
      return alert("Geolocalizacion no disponible en su navegador.")
    }
    setDisable(true)
    const directionsService = new google.maps.DirectionsService()

    navigator.geolocation.getCurrentPosition(
      position => {
        const origin = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setDirections(result)
            } else {
              setDisable(false)
              console.error(`error fetching directions ${result}`)
            }
          }
        )
        setDisable(false)
      },
      () => {
        setDisable(false)
      },
      { enableHighAccuracy: true }
    )
  }

  return (
    <div className={classes.root} id="ubicacion">
      <h2>Ubicación</h2>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <div className={classes.info}>
            <h3>
              Consultorio
              <br className={classes.mobile} />
              <span> S.M. de Tucumán</span>
            </h3>
            <p>Instituto Campbell</p>
            <p>Salta 465</p>
            <p>San Miguel de Tucuman</p>
            <AnchorLink to="/#mapa">
              <Button
                variant="outline-primary"
                disabled={disable}
                onClick={() =>
                  handleRoute({ lat: -26.8235226, lng: -65.209092 })
                }
              >
                Como Llegar
              </Button>
            </AnchorLink>
          </div>
        </div>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          containerElement={<div className={classes.map} id="mapa" />}
          mapElement={
            <div style={{ height: "100%", width: "100%" }} id="mapa" />
          }
          loadingElement={<p>Loading...</p>}
          directions={directions}
        />
      </div>
    </div>
  )
}

export default LocationSection
