import React from "react"
import classes from "./InfoSection.module.css"
import InfoCard from "./InfoCard/InfoCard"

const InfoSection = () => {
  const data = [
    {
      slug: "cirugias",
      title: "Cirugías",
      summary:
        "Especialista en Cirugía de Rodilla y Miembros Superior e Inferior - Cirugía Mini Invasivas - Microcirugías - Cirugía Percutanea - Reemplazos Articulares.",
    },
    {
      slug: "artroscopia",
      title: "Cirugía Artroscópica",
      summary:
        "Lesiones Meniscales - Lesiones Ligamentarias de Rodilla y Hombro - Artroscopía de Tobillo y Muñeca. ",
    },
    {
      slug: "traumatologia",
      title: "Traumatología y Ortopedia de Niños y Adultos",
      summary: "Pie Plano - Escoliosis - Deformidades Congénitas - Lesiones.",
    },
    {
      slug: "lesiones-deportivas",
      title: "Lesiones Deportivas",
      summary:
        "Ruptura Meniscales y Ligamentos Cruzados - Esguinces - Lesiones Manguito Rotador de Hombro - Lesiones Musculares.",
    },
    {
      slug: "tratamientos-biologicos",
      title: "Tratamientos Biológicos con Factores de Crecimiento",
      summary:
        "Tratamiento para Rápida Recuperación de Lesiones Deportivas, Degenerativas y Artrosis.",
    },
    {
      slug: "enfermedades-tratadas",
      title: "Enfermedades Tratadas",
      summary: "",
    },
  ]

  const items = data.map(item => {
    return (
      <InfoCard
        title={item.title}
        summary={item.summary}
        slug={item.slug}
        key={item.slug}
      />
    )
  })

  return (
    <div className={classes.root} id="especialidades">
      <h2>Especialidades</h2>
      <div className={classes.cards}>{items}</div>
    </div>
  )
}

export default InfoSection
