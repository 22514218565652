import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classes from "./GallerySection.module.css"
import Carousel from "react-bootstrap/Carousel"
import Modal from "./Modal/Modal"

const GallerySection = () => {
  const [modal, toggleModal] = useState(false)
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState(null)

  const handleOpen = id => {
    const img = data.allContentfulGallery.edges[0].node.photos.find(
      photo => photo.id === id
    )
    setImageId(id)
    setImage(img.high)
    toggleModal(true)
  }

  const handleClose = () => {
    toggleModal(false)
  }

  const handleNext = e => {
    if (e.key === "ArrowRight") {
      let currImgIndex = data.allContentfulGallery.edges[0].node.photos.findIndex(
        x => x.id === imageId
      )
      const nextImage =
        data.allContentfulGallery.edges[0].node.photos[currImgIndex + 1]
      if (nextImage) {
        setImageId(nextImage.id)
        setImage(nextImage.high)
      }
    } else if (e.key === "ArrowLeft") {
      let currImgIndex = data.allContentfulGallery.edges[0].node.photos.findIndex(
        x => x.id === imageId
      )
      const prevImage =
        data.allContentfulGallery.edges[0].node.photos[currImgIndex - 1]
      if (prevImage) {
        setImageId(prevImage.id)
        setImage(prevImage.high)
      }
    } else if (e.key === "Escape") {
      toggleModal(false)
    }
  }

  const handleEnter = (e, id) => {
    if (e.key === "Enter") {
      handleOpen(id)
    }
  }

  const data = useStaticQuery(graphql`
    query {
      allContentfulGallery {
        edges {
          node {
            photos {
              id
              low: fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
                ...GatsbyContentfulFluid
              }
              high: fluid(
                maxWidth: 1000
                quality: 100
                resizingBehavior: NO_CHANGE
              ) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const gallery = data.allContentfulGallery.edges[0].node.photos.map(photo => {
    return (
      <div
        className={classes.galleryImage}
        onClick={() => handleOpen(photo.id)}
        key={photo.id}
        onKeyDown={e => handleEnter(e, photo.id)}
        tabIndex="0"
        role="button"
      >
        <Img fluid={photo.low} />
      </div>
    )
  })

  const carousel = data.allContentfulGallery.edges[0].node.photos.map(photo => {
    return (
      <Carousel.Item key={photo.id}>
        <Img fluid={photo.high} />
      </Carousel.Item>
    )
  })

  return (
    <div className={classes.root} id="fotos">
      <h2>Fotos</h2>
      <div className={classes.slider}>{gallery}</div>
      <Carousel className={classes.galleryCarousel}>{carousel}</Carousel>
      {modal ? (
        <Modal toggle={handleClose} image={image} next={handleNext} />
      ) : null}
    </div>
  )
}

export default GallerySection
