import React, { useContext } from "react"
import { Context } from "../../context"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classes from "./Doctor.module.css"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import Whatsapp from "../../assets/whatsapp.svg"

const Doctor = () => {
  const { toggleModal } = useContext(Context)
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeroImages {
        edges {
          node {
            photos {
              id
              fluid(
                maxWidth: 1000
                maxHeight: 1000
                quality: 100
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const carousel = data.allContentfulHeroImages.edges[0].node.photos.map(
    photo => {
      return (
        <Carousel.Item key={photo.id}>
          <Img fluid={photo.fluid} alt="" />
        </Carousel.Item>
      )
    }
  )

  return (
    <>
      <div className={classes.root} id="inicio">
        <div className={classes.infoContainer}>
          <h1>
            Dr. Claudio Maldonado, <br />
            <span>Traumatólogo.</span>
          </h1>
          <p>
            Con más de 25 años de trayectoria en el medio, formación académica
            nacional e internacional y gran experiencia en cirugías de Alta
            complejidad Ortopédica y Traumatológica
          </p>
          <Button
            variant="light"
            className={classes.heroBtn}
            onClick={toggleModal}
          >
            <span>Reservar Turno</span>
            <Whatsapp />
          </Button>
        </div>
        <div className={classes.floatRight}>
          <div className={classes.imgContainer}>
            <Carousel className="heroCarousel">{carousel}</Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default Doctor
