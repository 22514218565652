import React from "react"
import classes from "./ExperienceSection.module.css"
import ExperienceColumn from "./ExperienceColumn/ExperienceColumn"
import Membership from "../../assets/membership.svg"
import Certificate from "../../assets/certificate.svg"
import Hospital from "../../assets/hospital.svg"

const ExperienceSection = () => {
  const membershipList = [
    "Miembro adherente de la Asociación Argentina de Ortopedia y Traumatología (A.A.O.T.)",
    "Miembro adherente de la Asociación Argentina de Artroscopía (A.A.A.)",
    "Miembro adherente de la Asociación Tucumana de Ortopedia y Traumatología (A.T.O.T.)",
  ]

  const certificateList = [
    "Especialista certificado de la Asociación Argentina de Ortopedia y Traumatología (A.A.O.T.)",
    "Especialista certificado de la Asociación Argentina de Atroscopía.",
    "Cirujano Artroscopista certificado de la Asociación Argentina de Atroscopía.",
  ]

  const hospitalList = [
    "Ex Fellowship Indiana Hand Center (Indianápolis USA)",
    "Ex Residente del Instituto Dupuytren de Ortopedia y Traumatología Bs. As.",
    "Ex Concurrente de Hospital Sport and Medicine Miami (Florida, USA)",
  ]

  return (
    <div className={classes.root} id="experiencia">
      <h2>Experiencia</h2>
      <div className={classes.container}>
        <ExperienceColumn SvgImage={Membership} itemsList={membershipList} />
        <ExperienceColumn SvgImage={Certificate} itemsList={certificateList} />
        <ExperienceColumn SvgImage={Hospital} itemsList={hospitalList} />
      </div>
    </div>
  )
}

export default ExperienceSection
