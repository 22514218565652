// import React, { useState } from "react"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classes from "./ReviewSection.module.css"
// import ReviewModal from "./ReviewModal/ReviewModal"
import Button from "react-bootstrap/Button"
import Carousel from "react-bootstrap/Carousel"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ReviewSection = () => {
  // const [modal, setModal] = useState(false)

  // const toggleModal = () => {
  //   setModal(prevState => !prevState)
  // }

  const data = useStaticQuery(graphql`
    query {
      allContentfulOpinion {
        edges {
          node {
            name
            review
            id
          }
        }
      }
    }
  `)

  const reviewGrid = data.allContentfulOpinion.edges.map(review => {
    return (
      <div
        className={[classes.review, classes.gridItem].join(" ")}
        key={review.node.id}
      >
        <div className={classes.content}>{review.node.review}</div>
        <div className={classes.name}>- {review.node.name}</div>
      </div>
    )
  })

  const reviewCarousel = data.allContentfulOpinion.edges.map(review => {
    return (
      <Carousel.Item key={review.node.id}>
        <div className={classes.review}>
          <div className={classes.content}>{review.node.review}</div>
          <div className={classes.name}>- {review.node.name}</div>
        </div>
      </Carousel.Item>
    )
  })

  return (
    <div className={classes.root} id="opiniones">
      <h2>Opiniones de Pacientes</h2>
      <div className={classes.gridContainer}>
        {reviewGrid}
        <Carousel
          className={[classes.reviewCarousel, "review-carousel"].join(" ")}
        >
          {reviewCarousel}
        </Carousel>
        <AnchorLink className={classes.submitButton} to="/#review">
          <Button variant="outline-primary" className={classes.color}>
            Dejar Opinion
          </Button>
        </AnchorLink>
      </div>
      {/* {modal ? <ReviewModal toggle={toggleModal} /> : null} */}
    </div>
  )
}

export default ReviewSection
