/*global google*/
import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps"

const Map = ({ directions }) => {

    return (
        <GoogleMap
            defaultZoom={17}
            defaultCenter={{ lat: -26.8235226, lng: -65.209092 }}
        >
            <Marker position={{ lat: -26.8235226, lng: -65.209092 }} />
            {directions ? <DirectionsRenderer directions={directions} /> : null}
        </GoogleMap>
    )
}

export default withScriptjs(withGoogleMap(Map))