import React, { Fragment, useRef, useEffect } from "react"
import Img from "gatsby-image"
import classes from "./Modal.module.css"
import Close from "../../../assets/close.svg"

const Modal = ({ toggle, image, next }) => {
  const imageContainer = useRef(null)

  useEffect(() => {
    imageContainer.current.focus()
  }, [])

  return (
    <Fragment>
      <div className={classes.backdrop} onClick={toggle} />
      <Close className={classes.closeIcon} onClick={toggle} />
      <div
        className={classes.root}
        onKeyDown={next}
        tabIndex="0"
        ref={imageContainer}
      >
        <Img
          fluid={image}
          style={{ padding: "1rem", maxHeight: "calc(100vmin - 4rem)" }}
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </Fragment>
  )
}

export default Modal
