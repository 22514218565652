import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

const InfoCard = ({ title, summary, slug }) => {
  return (
    <div>
      <div>
        <h3>
          <Link to={`/${slug}`}>{title}</Link>
        </h3>
      </div>
      <div>
        <p>{summary}</p>
      </div>
      <Link to={`/${slug}`}>
        <Button variant="outline-secondary">Mas Info</Button>
      </Link>
    </div>
  )
}

export default InfoCard
